.cont_course_details {
  background-color: #833988;
  width: 100%;
  max-width: 100%;
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dep_teacher_coursedetails {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img_coursedetails{
  width: 600px;
  height: 300px;
  border-radius: 15px;
  object-fit: contain;
}
.dep_coursedetaile {
  font-size: 16px;
  font-weight: 600px;
  line-height: 30px;
  color: #fff;
  padding: 5px;
  background-color: #f57d20;
  border-radius: 10px;
  margin: 5vh;
}
.teacher_coursedetails {
  font-size: 16px;
  font-weight: 500px;
  line-height: 30px;
  color: #fff;
}
.title_coursedetails {
  color: #fff;
  /* font-size: 44px; */
  font-weight: 700;
  line-height: 47px;
  padding: 3vh;
}
/* end header course details */
.col_tabs_coursedetails {
  /* border: 1px solid #eaeaea; */
  border-radius: 10px;
}
.tabs-container{
  border-top: 1px solid #eaeaea;;
border-left: 1px solid #eaeaea;
border-right:1px solid #eaeaea;
border-radius: 20px;
}
.tabs_cont_coursedetails {
  padding: 10px;
  background-color: #f0f2f5;
  border-radius: 10px;
}

.tab {
  padding: 15px 51px 15px 51px;
  background-color: #ffffff;
  border: 0px;
  border-radius: 10px 15px 1px 1px;
  font-weight: 600px;
  line-height: 24px;
}

.selected {
  background-color: #f0f2f5;
  color: #f57d20;
}

.description_coursedetails {
  font-size: 15px;
  font-weight: 400;
  text-align: right;
  line-height: 27px;
}
.frame {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;}
  .frame-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
  }
  
  .frame-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  

  .video-overlay{
    background-image: url('../assets/ba9ma2.webp');
  }
 
  .video_cont {
    width: 100%;
    height: 450px; /* You can adjust this as needed */
    background-color: #F0F2F5;
    border-radius: 15px;
  }
  
  .video_wrapper {
    width: 100%;
    padding-bottom: 30%; /* This maintains the 30% height relative to the width */
    height: 100%;
  }
  
  .video_play {
    top: 0;
    left: 0;
    width: 100%;
    height: 280px;
    object-fit: cover; /* Maintain aspect ratio and cover the area */
    border-radius: 20px;
  }
  
  .after_price_coursedetails{
    color: #833988;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }
  .before_price_coursedetails{
    font-size: 28px;
    font-weight: 400;
    line-height: 48px;
    color: #9D9D9D;
    text-decoration:line-through;
    margin-right: 8vh;
  }
  .purchase_now_coursedetails{
    width: 232px;
    height: 45px;
    border-radius: 20px;
    background-color: #833988;
    color: #fff;
    border: none;
  }
  .purchase_now_coursedetails:hover{
    background-color: #a767ac;
    color: #fff;
  }
.title_after_purchase{
  color: #833988;
  font-weight: 700;
}
.teachar_after_purchase{
  color: #f57d20;
}
  /* .topics_cont_coursedetails[open] .title_of_video_coursedetails:before {
    content: "\25be";
    
  } */
/* End topics section */

/* Teacher section */
.teacher_name_coursedetails{
font-size:18px ;
font-weight: 600;
text-align: right;
line-height: 27px;
}
.desc_of_teacher_coursedetails{
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    line-height: 20px;
}
/* End Teacher section */
/* feedback************************ */
.comment_date_coursedetails{
font-size: 17px;
line-height: 24px;
font-weight: 600 ;  

}
.checkbox_coursedetails{
  height: 20px;
  width: 20px;
  margin-left: 13px;
  margin-top: 5px;
}
/* Custom styles for Read more button */
.read-more-button {
  color: #833988;              /* Text color */
  border: none;              /* Remove border */
  padding: 8px 10px;        /* Padding inside the button */
  cursor: pointer;           /* Pointer cursor on hover */
  font-size: 14px;           /* Font size */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.read-more-button:hover {
  color: #f57d20; /* Background color on hover */
}

/* End feedback************************ */
@media screen and (max-width: 768px) {
    #title_of_video_coursedetails:before {
    left:93%
    }
    
}