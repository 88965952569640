.courses_margin {
  margin-top: 20vh;
}
.navbar__search {
  position: relative;
  color: #aaa;
  font-size: 16px;
  width: 100%;
}

.navbar__search {
  display: inline-block;
}

.search_course {
  width: 100%;
  height: 45px;
  background: #fcfcfc;
  border: 1px solid #aaa;
  text-indent: 32px;
  border-radius: 85px;
}

.navbar__search span {
  position: absolute;
  top: 10px;
  font-size: 20px;
  right: 10px;
}
.search_btn {
  position: absolute;
  top: 3px;
  left: 4px;
  font-size: 17px;
 /* padding-left: 15px; */
 padding-right: 15px;
  padding-bottom: 30px;
  height: 35px;
  width: 80px;
}

.search_dropdown {
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  position: absolute; 
  top: 100%;
  left: 0;
  width: 100%; 
  z-index: 999; 
}

.search_dropdown li {
  padding: 8px;
  cursor: pointer;
}

.search_dropdown li:hover {
  background-color: #f5f5f5;
}

.navbar__search ul li img {
  width: 50px;
  border-radius: 50%;
  margin: 10px 24px;
}
.change_specific_title_slider{
  font-size: 100px;

}
.select_dep {
  border-radius: 30px;
  max-width: 100%;
  width: 100%;
  padding: 5px;
  border-color: #aaa;
}
.select_dep option {
  color: #833988;
}

/* card slider ******************************/
.card_col{
    display: flex;
justify-content: center;
align-items: center;
}
.card_cont {
  width: 90%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: none;
  border-radius: 25px;
  margin: 5% ;
/* margin-left: 8vh; */
}
.card_img {
  border-radius: 25px;
  padding: 10px;
  height: 200px;
  object-fit: contain;

}
.card_dep {
  text-align: right;
  font-size: 14px;
  font-weight: 600px;
  line-height: 30px;
  color: #acacac;
}
.course_title_card {
  font-size: 20px;
  font-weight: bold;
  line-height: 37px;
  text-align: right;
}
.teacher_name_card {
  font-size: 16px;
  font-weight: 400px;
  line-height: 37px;
  color: #833988;
}
.details_courses_card {
  font-size: 16px;
  font-weight: 600px;
  line-height: 30px;
  color: #acacac;
}
.link_card{
  text-decoration: none;

}
.card_icon {
  line-height: 30px;
}

.col_btn_prevNext{
    display: flex;
    justify-content: center;
}


/* End card slider ******************************/
/* Modal *************************/
.modal_title{
  text-align: center;
  color: #833988;
  font-size: 34px;
  font-weight: 700;
  line-height: 100px;
}
.input_filed_modal{
  border-radius: 20px;
border-color: #aaa
}
.text_field{
  text-align: center;
  color: #833988;
}
.buy_department_btn{
  background-color: #833988;
  border: none;
  border-radius: 20px;
  width: 100%;
}
.buy_department_btn:hover{
  background-color: #F57D20;
}
.purchaseDepartment_box{
    background-color: #833988;
    border-radius: 22px;
    /* position: absolute; */
    bottom: -40px; /* Position it initially below the bottom */
    left: 50%; /* Center horizontally */
    padding: 20px;
    width: 100%; /* Adjust width as needed */
    max-width: 1300px; /* Max width to limit on larger screens */
  margin-top: 5vh ;
  margin-bottom: 5vh ;
}
@media screen and (max-width: 1875px) {
  .courses_margin {
    margin-top: 120px;
  }
}
@media screen and (max-width: 992px) {
  .courses_margin {
    margin-top: 100px;
  }
}
@media screen and (max-width: 768px) {
  .courses_margin {
    margin-top: 50px;
  }

}
