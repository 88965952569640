.slide-item {
  position: relative;
  width: 100%;
  height: 80vh;
  
}
.fixed_image_slider {
  position: relative; 
  background-image: url('../assets/course.webp');
  background-size: cover; 
  background-position: center; 
  width: 100%; 
  height: 78vh; 
    background-color: rgba(0, 0, 0, 0.1); 
  background-blend-mode: overlay; 
}

.img_home {
  width: 100%; /* Ensures the image width fills its container */
  height: 78vh; /* Sets height to 100% of viewport height */
  object-fit: cover; /* Ensures the image covers the container without stretching */
  display: block; /* Ensures the image behaves as a block element */
}
.title_of_slidercomp{
  /* font-size: 520vh;  */
  font-weight: 700;
  line-height: 73px;
}
.paragraph_slider{
  font-size: 16px;
  font-weight: 500px;
  line-height: 30px;
  color: #fff !important;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 78vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 20px;
  text-align: center;
}

.overlay-content {
  max-width: 80%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.overlay-content img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.overlay-content p {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.overlay-content .btn {
  font-size: 0.85rem;
  margin-top: 5%;
  line-height: 1.9;
}
.slick-dots {
  bottom: 10vh;
  color: #fff;
}
.slick-dots li.slick-active button:before {
  color: #fff;
}
.slick-dots li button:before {
  color: #fff;
}

/* end slider ********************************************/

/*  slider box **********************************/


.slider_box {
  background-color: #833988;
  border-radius: 22px;
  position: absolute;
  bottom: -40px; /* Position it initially below the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  padding: 20px;
  width: 100%; /* Adjust width as needed */
  max-width: 1300px; /* Max width to limit on larger screens */
  min-height: 200px;
}
.h_box_slider {
  color: #f57d20;
  text-align: right;
  font-weight: bold;
}
.p_box_slider {
  color: #fff;
  text-align: right;
  font-size: 12px;
  line-height: 28px;
}

.vector_cont {
  background-color: #a767ac;
  height: 53px;
  width: 55px;
  border-radius: 10px;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center;
}

.vector_icon {
  height: 33px;
  width:37px;
}
.hidden_btn {
  display: none;
}

/* end slider box **********************************/
 @media screen and (max-width: 768px){
  .title_of_slidercomp{
  line-height: 25px;
  }
 
 }
